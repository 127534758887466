// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

//export const YM_AdminURL = "https://localhost:44382/";
// //uat
// export const YM_AdminURL = "https://adminuat.ymmississauga.ca/";
// //:Live
// export const YM_AdminURL = "https://adminuat.ymmississauga.ca/";


//uat
export const YM_AdminURL = "https://adminuat.ymcanada.ca/";


//:Live
//export const YM_AdminURL = "https://admin.ymcanada.ca/";
